<template>
  <div class="container">
    <div class="lineH-box">
      <div class="lineH"></div>
      <div>基本信息</div>
    </div>
    <div class="table-info">
      <div class="info-row">
        <div class="info-list" v-if="form.goods_name">
          <div class="info-head">商品名称：</div>
          <div class="info-details">{{ form.goods_name }}</div>
        </div>
        <div class="info-list" v-if="form.sort">
          <div class="info-head">排序：</div>
          <div class="info-details">{{ form.sort }}</div>
        </div>
        <div class="info-list" v-if="form.goods_code">
          <div class="info-head">商品编码：</div>
          <div class="info-details">{{ form.goods_code }}</div>
        </div>
        <div class="info-list" v-if="category">
          <div class="info-head">平台类目：</div>
          <div class="info-details">{{ category }}</div>
        </div>
        <div class="info-list">
          <div class="info-head">商品可见：</div>
          <div class="info-details">
            {{ form.is_show == "1" ? "是" : "否" }}
          </div>
        </div>
        <div class="info-list">
          <div class="info-head">商品类型：</div>
          <div class="info-details">
            {{ form.goods_type == "1" ? "实体商品" : "虚拟商品" }}
          </div>
        </div>
        <div class="info-list">
          <div class="info-head">开启售后：</div>
          <div class="info-details">
            {{ form.is_service == "1" ? "是" : "否" }}
          </div>
        </div>
        <div class="info-list" v-if="form.price">
          <div class="info-head">商品价格：</div>
          <div class="info-details">￥{{ form.price }}</div>
        </div>
        <div class="info-list" v-if="form.member_price">
          <div class="info-head">会员价格：</div>
          <div class="info-details">{{ form.member_price }}</div>
        </div>
        <div class="info-list" v-if="form.stock">
          <div class="info-head">商品数量：</div>
          <div class="info-details">{{ form.stock }}</div>
        </div>
        <div class="info-list" v-if="form.goods_weight">
          <div class="info-head">商品重量：</div>
          <div class="info-details">{{ form.goods_weight }}g</div>
        </div>
        <div class="info-list" v-if="form.limit_time.length">
          <div class="info-head">限时售卖：</div>
          <div class="info-details">
            {{ dataPars(form.limit_time[0]) }}至
            {{ dataPars(form.limit_time[1]) }}
          </div>
        </div>
        <div class="info-list">
          <div class="info-head">是否上架：</div>
          <div class="info-details">
            {{
              form.state == "1"
                ? "下架"
                : form.state == "2"
                ? "正常"
                : form.state == "5"
                ? "暂存仓库"
                : form.state == "6"
                ? "自动下架"
                : ""
            }}
          </div>
        </div>
        <div class="info-list" v-if="form.sales">
          <div class="info-head">虚拟出售数：</div>
          <div class="info-details">{{ form.sales }}</div>
        </div>
        <div class="info-list">
          <div class="info-head">启用规格：</div>
          <div class="info-details">{{ form.open_spec ? "是" : "否" }}</div>
        </div>
        <div class="info-list">
          <div class="info-head">启用积分：</div>
          <div class="info-details" style="margin-right: 5px">
            {{ isIntegral ? "是" : "否" }}
          </div>
          <div class="info-details" v-if="isIntegral">
            积分抵扣最大金额{{ form.integral_price }}元
          </div>
        </div>
        <div class="info-list">
          <div class="info-head">商品分享：</div>
          <div class="info-details">
            {{ form.is_share ? "可分享" : "不可分享" }}
          </div>
        </div>
      </div>
      <div class="pictur-list" v-if="form.img_id_array.length">
        <div class="pictur-head">轮播图片：</div>
        <div class="pictur-details">
          <div class="pictur-box" v-for="item in form.img_id_array" :key="item">
            <el-image
              fit="fill"
              :src="item"
              style="width: 100px; height: 100px"
            />
          </div>
        </div>
      </div>
      <div class="pictur-list chooseVideoBox">
        <div class="pictur-head">商品视频：</div>
        <div class="pictur-details" v-if="chooseVideo.video_url_str">
          <video
            :src="chooseVideo.video_url_str"
            style="width: 150px;height: 120px;"
            :poster="chooseVideo.mian_url_str"
            controls
            @click="openPreview"
          ></video>
        </div>
      </div>
    </div>
    <div class="lineH-box">
      <div class="lineH"></div>
      <div>商品参数</div>
    </div>
    <div class="table-info">
      <div class="info-row">
        <div
          class="info-list"
          v-for="(item, index) in form.parameter_content"
          :key="index"
        >
          <div class="info-head">{{ item.name }}：</div>
          <div class="info-details">{{ item.value }}</div>
        </div>
      </div>
    </div>
    <div class="lineH-box">
      <div class="lineH"></div>
      <div>商品规格</div>
    </div>
    <div class="goods-info" v-if="form.open_spec">
      <el-table
        :data="tableData"
        border
        style="width: auto"
        tooltip-effect="dark"
        :header-cell-style="{ background: '#F5F5F5' }"
      >
        <el-table-column
          v-for="(item, index) in labelList"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="goods_evaluate" v-else>
      暂无数据
    </div>
    <div></div>
    <div class="lineH-box" v-if="form.description.length">
      <div class="lineH"></div>
      <div>商品详情</div>
    </div>
    <div class="descriptionPictur">
      <div v-for="item in form.description" :key="item">
        <el-image
          :src="item"
          style="
            width: 100px;
            height: 100px;
            text-align: center;
            margin-right: 5px;
          "
          fit="fill"
        ></el-image>
      </div>
    </div>
    <div class="lineH-box">
      <div class="lineH"></div>
      <div>推荐商品</div>
    </div>
    <div style="display: flex;">
      <div
        class="recommendBox"
        v-for="(i, index) in form.recommend_list"
        :key="index"
      >
        <div class="itemBox">
          <img :src="i.picture" alt="" />
        </div>
        <div class="title">{{ i.goods_name }}</div>
      </div>
    </div>
    <div class="lineH-box">
      <div class="lineH"></div>
      <div>配送设置</div>
    </div>
    <div class="deviler">
      <div style="margin-right: 5px">
        {{
          form.shipping_fee_type == 1
            ? "统一运费"
            : form.shipping_fee_type == 3
            ? "仅自提"
            : form.shipping_fee_type == 2
            ? "运费模板"
            : ""
        }}
      </div>
      <div v-if="form.shipping_fee_type == 1">￥{{ form.shipping_fee }}元</div>
      <div v-if="form.shipping_fee_type == 2">{{ name }}</div>
    </div>
    <div class="lineH-box">
      <div class="lineH"></div>
      <div>商品评价</div>
    </div>
    <div class="goods_evaluate" v-if="form.all_evaluate_count">
      <div style="display: flex;align-items: center;">
        <div>综合评分：</div>
        <div style="margin-right: 20px;">{{ form.evaluate || "无" }}分</div>
        <div>好评率：</div>
        <div style="margin-right: 20px;">{{ form.favorable_rate || 0 }}</div>
        <div style="color: #4974f5;cursor: pointer;" @click="checkDetails">
          查看明细
        </div>
      </div>
      <div style="display: flex;align-items: center;">
        <div style="margin-right: 20px;">
          评价条数：{{ form.all_evaluate_count || 0 }}条
        </div>
        <div style="margin-right: 20px;">
          好评：{{ form.good_evaluate_count || 0 }}条
        </div>
        <div style="margin-right: 20px;">
          中评：{{ form.mid_evaluate_count || 0 }}条
        </div>
        <div style="margin-right: 20px;">
          差评：{{ form.bad_evaluate_count || 0 }}条
        </div>
      </div>
    </div>
    <div class="goods_evaluate" v-else>
      暂无评价数据
    </div>
    <!-- 模态框 -->
    <div
      v-if="isPreviewVisible"
      class="preview-modal"
      @click.self="closePreview"
      style="z-index: 999;"
    >
      <video
        ref="previewPlayer"
        width="800"
        height="450"
        :poster="chooseVideo.mian_url_str"
        controls
        @click.stop
      >
        <source :src="chooseVideo.video_url_str" type="video/mp4" />
      </video>
    </div>
  </div>
</template>
<script>
export default {
  name: "checkGood",
  data() {
    return {
      goods_id: this.$route.query.goods_id,
      form: {
        sort: undefined,
        goods_name: "",
        goods_code: "",
        category_id_1: "",
        category_id_2: "",
        goods_type: "1",
        goods_weight: undefined,
        img_id_array: [],
        price: undefined,
        cost_price: undefined,
        member_price: undefined,
        stock: undefined,
        limit_time: [],
        state: "2",
        sales: undefined,
        shipping_fee_type: "1",
        shipping_fee: undefined,
        shipping_fee_id: "",
        description: [],
        open_spec: false,
        spec_list: [], //规格列表
        sku_list: [], //sku列表
        is_show: 1,
        is_service: 0,
        integral_price: 0,
        is_share: 1,
        parameter_content: [],
      },
      category: undefined,
      isIntegral: false,
      tableData: [],
      labelList: [],
      name: [], //运费模板
      isPreviewVisible: false,
      chooseVideo: {}, //符合条件的视频
    };
  },
  mounted() {
    this.getGoodsInfo();
    this.getShippingFee();
  },
  methods: {
    getGoodsInfo() {
      let data = {
        goods_id: this.goods_id,
      };
      this.axios.post("/store/Shopgoods/getGoodsInfo", data).then((res) => {
        let info = res.data.info;
        let form = {
          sort: info.sort,
          goods_name: info.goods_name,
          goods_code: info.goods_code,
          goods_type: info.goods_type.toString(),
          goods_weight: info.goods_weight || undefined,
          img_id_array: info.img_id_array_str || [],
          price: info.price || undefined,
          cost_price: info.cost_price || undefined,
          member_price: info.member_price || undefined,
          stock: info.stock || undefined,
          limit_time: [],
          state: info.state.toString(),
          sales: info.sales || undefined,
          shipping_fee_type: info.shipping_fee_type.toString(),
          shipping_fee: info.shipping_fee,
          shipping_fee_id: info.shipping_fee_id || "",
          description: info.description_str || [],
          open_spec: info.open_spec == 1 ? true : false,
          spec_list: info.spec_value_items || [],
          sku_list: info.sku_list || [],
          is_show: info.is_show.toString(),
          is_service: info.is_service,
          integral_price: info.integral_price,
          category_id_1: info.category_id_1,
          category_id_2: info.category_id_2,
          is_share: info.is_share,
          evaluate: info.evaluate,
          favorable_rate: info.favorable_rate,
          all_evaluate_count: info.all_evaluate_count,
          good_evaluate_count: info.good_evaluate_count,
          mid_evaluate_count: info.mid_evaluate_count,
          bad_evaluate_count: info.bad_evaluate_count,
          favorable_rate: info.favorable_rate,
          category_id_list: info.category_id_list,
          parameter_content: info.parameter_content,
          recommend_list: info.recommend_list,
        };
        this.chooseVideo = {
          mian_url: info.mian_url,
          mian_url_str: info.mian_url_str,
          video_url: info.video_url,
          video_url_str: info.video_url_str,
        };
        if (info.limit_time_start) {
          form.limit_time = [
            info.limit_time_start * 1000,
            info.limit_time_end * 1000,
          ];
        }
        if (form.member_price == 0) {
          form.member_price = undefined;
        }
        if (form.cost_price == 0) {
          form.cost_price = undefined;
        }
        form.sku_list.forEach((ele) => {
          if (ele.member_price == 0) {
            ele.member_price = undefined;
          }
          if (ele.cost_price == 0) {
            ele.cost_price = undefined;
          }
          if (ele.weight == 0) {
            ele.weight = undefined;
          }
        });
        this.form = form;
        this.isIntegral = form.integral_price == 0 ? false : true;
        let firstLable = [];
        let labelName = "规格";
        if (this.form.spec_list.length) {
          firstLable = this.form.spec_list.map((res) => {
            return {
              label: res.spec_name,
            };
          });
          if (firstLable.length > 1) {
            // labelName = '规格' + '(' + firstLable[0].label + '/' + firstLable[1].label + ')'
            this.form.sku_list = this.form.sku_list.map((res) => {
              return {
                ...res,
                sku_1: res.sku_name.split(/\s+/)[0],
                sku_2: res.sku_name.split(/\s+/)[1],
              };
            });
            this.labelList = [
              { label: firstLable[0].label, prop: "sku_1", width: "auto" },
              { label: firstLable[1].label, prop: "sku_2", width: "auto" },
              { label: "价格（元）", prop: "price", width: "auto" },
              { label: "会员价", prop: "member_price", width: "auto" },
              { label: "数量（件）", prop: "stock", width: "auto" },
              { label: "商品编码", prop: "goods_code", width: "auto" },
              { label: "商品重量（g）", prop: "weight", width: "auto" },
              { label: "商品备注", prop: "remark", width: "auto" },
            ];
          } else {
            // labelName = '规格' + '(' + firstLable[0].label + ')'
            this.labelList = [
              { label: firstLable[0].label, prop: "sku_name", width: "250" },
              { label: "价格（元）", prop: "price", width: "auto" },
              { label: "会员价", prop: "member_price", width: "auto" },
              { label: "数量（件）", prop: "stock", width: "auto" },
              { label: "商品编码", prop: "goods_code", width: "auto" },
              { label: "商品重量（g）", prop: "weight", width: "auto" },
              { label: "商品备注", prop: "remark", width: "auto" },
            ];
          }
        }

        this.tableData = this.form.sku_list;
        this.getCategoryList();
      });
    },
    //平台分类列表
    getCategoryList() {
      this.axios.post("/store/Shopgoods/getCategoryList").then((res) => {
        let list = res.data.list;
        let arr = [];
        list.forEach((ele) => {
          if (ele.child.length == 0) {
            arr.push({
              value: ele.category_id,
              label: ele.category_name,
            });
          } else {
            let childArr = [];
            ele.child.forEach((e) => {
              childArr.push({
                value: e.category_id,
                label: e.category_name,
              });
            });
            arr.push({
              value: ele.category_id,
              label: ele.category_name,
              children: childArr,
            });
          }
        });
        var list_arr = [];
        this.form.category_id_list.map((el) => {
          let a = arr.filter((res) => {
            return res.value == el.category_id_1;
          })[0];
          let b = a.children.filter((res) => {
            return res.value == el.category_id_2;
          })[0];
          list_arr.push(a.label + "/" + b.label);
        });
        this.category = list_arr.join(",");
      });
    },
    //获取运费模板列表
    getShippingFee() {
      this.axios
        .post("/store/Shopshipping/getShopShippingList", {
          page: 1,
          pageNumber: 100,
        })
        .then((res) => {
          const feeList = res.data.list;
          if (this.form.shipping_fee_id) {
            this.name = feeList.filter((el) => {
              return this.form.shipping_fee_id === el.shipping_id;
            })[0].shipping_name;
          }
        });
    },
    // 查看详情
    checkDetails() {
      const goods_id = this.goods_id;
      const goods_name = this.form.goods_name;
      this.$router.push({
        path: "/goods/Evaluation",
        query: {
          goods_id,
          goods_name,
        },
      });
    },
    // 时间转换
    dataPars(data) {
      var date = new Date(data);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    openPreview() {
      // 点击视频时打开预览模态框
      this.isPreviewVisible = true;

      // 监听全局点击事件，以便在点击模态框外部时关闭预览
      window.addEventListener("click", this.globalClickHandler);
    },
    closePreview() {
      // 关闭预览模态框
      this.isPreviewVisible = false;

      // 移除全局点击事件监听器
      window.removeEventListener("click", this.globalClickHandler);

      // 重置预览视频的播放状态
      this.$refs.previewPlayer.currentTime = 0;
      this.$refs.previewPlayer.pause();
    },
    globalClickHandler(event) {
      // 在全局点击事件处理程序中检查点击的区域是否在预览模态框之外
      const previewModal = this.$refs.previewModal;
      if (previewModal && !previewModal.contains(event.target)) {
        this.closePreview();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.lineH-box {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #606266;
  font-weight: 700;
  margin-bottom: 20px;
  .lineH {
    width: 3px;
    height: 18px;
    background: #4974f5;
    border-radius: 21px;
    margin-right: 8px;
  }
}
.table-info {
  width: 80%;
  padding: 10px 11px;
  .info-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .info-list {
      width: 50%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .info-head {
        min-width: 70px;
      }
    }
  }
}
.pictur-list {
  display: flex;
}
.pictur-details {
  display: flex;
}
.pictur-box {
  width: 100%;
  height: 100%;
  margin-right: 5px;
}
.goods-info {
  padding: 10px 11px;
}
.empty-box {
  margin: 10px 20px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e4e4e4;
}
.descriptionPictur {
  padding: 10px 20px;
  width: 100%;
  display: flex;
}
.deviler {
  display: flex;
  padding: 10px 13px;
}
.goods_evaluate {
  padding: 10px 13px;
}

.preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-modal video {
  max-width: 100%;
  max-height: 100%;
}

.chooseVideoBox {
  position: relative;
  .closed {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    z-index: 99;
  }
}
.recommendBox {
  margin-right: 10px;
  width: 150px;
  .itemBox {
    width: 150px;
    height: 150px;
    position: relative;
    img {
      width: 150px;
      height: 150px;
    }
  }
  .title {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
  }
}
</style>
